
import { defineComponent, inject, watch, ref, reactive } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { toSearchListElement } from '@/modules/search'

import getInvoiceQuery from '@/graphql/invoice/get.gql'
import updateInvoiceMutation from '@/graphql/invoice/update.gql'
import deleteInvoiceMutation from '@/graphql/invoice/delete.gql'

import InvoiceForm from '@/components/forms/Invoice.form.vue'

export default defineComponent({
  name: 'InvoiceUpdate',
  props: ['elementId', 'listPaging'],
  components: {
    InvoiceForm
  },
  setup(props) {
    // get emitter
    const emitter: any = inject('$emitter')
    
    const invoiceFormRef = ref<null | any>(null)
  
    ////////// MODAL EDIT ID
    const editingElement = reactive({id: props.elementId})
    const editingElementOptions = reactive({enabled : false})
    const { onResult: getInvoiceOnResult } = useQuery(getInvoiceQuery, editingElement, editingElementOptions)

    getInvoiceOnResult(queryResult => {
      if(queryResult.networkStatus === 7) { // 7 mean OK https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts
        const data = queryResult.data.invoice.data.attributes

        if(invoiceFormRef.value) {
          invoiceFormRef.value.reset()
          for (const [key, value] of Object.entries(data)) {
            if(key === 'date' || key === 'client_arrival' || key === 'client_departure') {
              invoiceFormRef.value.form[key] = new Date(data[key])
            } else if (key === 'reservation') {
              if(data[key].data === null) {
                invoiceFormRef.value.form['reservationId'] = 0
                invoiceFormRef.value.preselectedReservation = { id: 0, name: '' }
              } else {
                invoiceFormRef.value.form['reservationId'] = data.reservation.data.id
                invoiceFormRef.value.preselectedReservation = toSearchListElement(data[key].data, 'reservation')
              }
            } else if (key === 'custom_products') {
              // Dégueulasse sa mère, mais obligé car apollo retourne des objects immuables
              // bordel invoiceFormRef.value.form[key] = helpers.unfreezeProducts(data.invoice.data.attributes[key])
            } else { // The rest is automatically set
              invoiceFormRef.value.form[key] = data[key]
            }
          }
        }
      }
    })

    watch(() => props.elementId, (newValue) => {
      // When this changes a new query is fired
      editingElement.id = newValue
      editingElementOptions.enabled = true
    })


    ////////// UPDATE QUERY
    const { mutate: updateInvoice, onDone: updateInvoiceDone, onError: updateInvoiceError } = useMutation(updateInvoiceMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updateInvoiceDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updateInvoiceError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updateInvoiceDone((QR) => {      
      emitter.emit("create-toast", {
        props: {
          title: "Facture modifiée.",
          text: QR.data.updateInvoice.data.id,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-update")
      emitter.emit("invoices-refresh")
    })
    updateInvoiceError((error) => {
      console.log(error)
      console.log('Edit invoice Error')
    })

    ////////// DELETE QUERY
    const { mutate: deleteInvoice, onDone: deleteInvoiceDone, onError: deleteInvoiceError } = useMutation(deleteInvoiceMutation)
    const removeStatus = reactive({
      success: false,
      error: false
    })
    deleteInvoiceDone((QR) => {
      emitter.emit("create-toast", {
        props: {
          title: "Facture supprimée",
          text: QR.data.deleteInvoice.data.id,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-update")
      emitter.emit("invoices-refresh")
    })
    deleteInvoiceError(() => {
      removeStatus.error = true
      removeStatus.success = false
    })

    ////////// SUBMIT Modal
    const updateElement = () => {
      const form = invoiceFormRef.value.form
      
      const custom_products: any[] = invoiceHelpers.customProductsToStrapi(form.custom_products)
      
      let reservationId = null
      if(form.reservationId != 0) {
        reservationId = form.reservationId
      }

      const queryInput = { 
        id: props.elementId,
        data: {
          name: form.name,
          date: helpers.dateobjToISO(form.date),
          custom_products: JSON.parse(JSON.stringify(custom_products)),
          client_name: form.client_name,
          client_email: form.client_email,
          client_phone: form.client_phone,
          client_arrival: helpers.dateobjToISO(form.client_arrival),
          client_departure: helpers.dateobjToISO(form.client_departure),
          paid: form.paid,
          due: form.to_pay,
          reservation: reservationId,
          sci: form.sciId
        }
      }

      //console.log(queryInput)

      updateInvoice(queryInput)
    }

    const deleteElement = () => {
      const queryInput = { 
        id: props.elementId,
      }

      deleteInvoice(queryInput)


    }


    // Testing purposes
    const fillForm = () => {
      invoiceFormRef.value.fillForm()
    }


    return {
      invoiceFormRef,
      updateElement,
      deleteElement,
      fillForm,
      updateStatus
    }
  }
});
