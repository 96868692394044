
import { defineComponent, computed, ref, watch } from "vue"

import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getMenagesQuery from '@/graphql/menage/gets.gql'
import getStatusMenageQuery from '@/graphql/menage/status.gql'
import toggleMenageMutation from '@/graphql/menage/toggle.gql'
import getPaiementsQuery from '@/graphql/paiement/gets.gql'
import addPaiementMutation from '@/graphql/paiement/create.gql'

export default defineComponent({
  name: 'MenageView',
  components: {
  },
  setup() {
    const { result: getMenagesQR, onResult: getMenages_onResult,  refetch: refetchMenagesQR } = useQuery(getMenagesQuery)
    const menagesQR = useResult(getMenagesQR, null, data => data.menages.data)

    const openMenage = ref({})
    watch(getMenagesQR, value => {
      openMenage.value = {}
      value.menages.data.forEach((item: any) => {
        if(item.attributes.closed_at === null) {
          openMenage.value = item
        }
      })
    })

    const paiementInput = ref(0)

    const { result: getStatusQR, refetch: refetchStatusQR  } = useQuery(getStatusMenageQuery)
    const statusQR = useResult(getStatusQR, null, data => data.statusMenage)
    
    const { result: getPaiementsQR, refetch: refetchPaiementsQR  } = useQuery(getPaiementsQuery)
    const paiementsQR = useResult(getPaiementsQR, null, data => data.paiements.data)

    let nowDate = ref(new Date())
    const paiementShow = ref(false)

    // Apollo Toggle Menage Mutation 
    const { mutate: mutate_toggleMenage, onDone: toggleMenageDone, onError: toggleMenageError } = useMutation(toggleMenageMutation)
    // Apollo Add Paiement Mutation 
    const { mutate: mutate_addPaiement, onDone: addPaiementDone, onError: addPaiementError } = useMutation(addPaiementMutation)

    toggleMenageDone(queryResult => {
      refetchMenagesQR()
      refetchStatusQR()
    })

    addPaiementDone(queryResult => {
      paiementInput.value = 0
      paiementShow.value = true
      setTimeout(() => {
        paiementShow.value = false
      }, 3000)
      refetchPaiementsQR()
    })

    const toggleMenage = () => {
      
      const queryInput = {}

      // Check if the menage is > 1 minutes to avoid useless entries
      
      if (confirm(confirmSentence.value)) {
        mutate_toggleMenage(queryInput)
      }
    }

    const confirmSentence = computed((): string => {
      let sentence = " le ménage ?"
      
      if (statusQR.value as any === false) {
        sentence = "Commencer"+sentence
      } else {
        sentence = "Terminer"+sentence
      }
      return sentence
    })

    const addPaiement = () => {

      // Form checks
      if(paiementInput.value === 0)
      return
      
      const queryInput = { 
        data: {
          amount: paiementInput.value,
          date: new Date()
        }
      }

      mutate_addPaiement(queryInput)
    }


    const dateobjToDisplay = (date: Date): string => {
      return date.toLocaleString('FR-fr', { weekday: "long", day: "numeric", month: 'long' })
    }

    const totalMenagesTime = computed((): string => {

      if(menagesQR.value) {
        const total = computeTotalMenagesTime(menagesQR.value)

        return dateDiffToString(total)

      } else {
        return ""
      }
    })

    const computeTotalMenagesTime = (menagesArray: any) => {
      let total = 0
      menagesArray.forEach((menage: any) => {
        if(menage.attributes.closed_at !== null) {
          const difference = new Date(menage.attributes.closed_at).getTime() - new Date(menage.attributes.createdAt).getTime()

          total += difference
        }
      })
      return total
    }

    const totalPaiements = computed((): number => {
      let total = 0

      if(paiementsQR.value) {
        paiementsQR.value.forEach((paiement: any) => {
          total += paiement.attributes.amount
        })
        return total
      } else {
        return 0
      }
    })

    const computeTotalPaiements = (paimentsArray: any) => {
      let total = 0
      paimentsArray.forEach((paiement: any) => {
        total += paiement.attributes.amount
      })
      return total
    }

    const dateDiffToString = (milliseconds: number, seconds = false): string => {
      let total = milliseconds
      var hh = Math.floor(total / 1000 / 60 / 60);
      total -= hh * 1000 * 60 * 60;
      var mm = Math.floor(total / 1000 / 60);
      total -= mm * 1000 * 60;
      var ss = Math.floor(total / 1000);
      total -= ss * 1000;
    
      let rData = ""

      if(milliseconds <= 0) {
        rData = "Lancement..."
      } else {
        rData = hh+"h "+mm+"m"
        if(seconds) {
          rData += " "+ss+"s"
        }
      }
      
      return rData
    }

    const sortedMenages = computed(() => {

      let sorted: any = {}
      if(menagesQR.value) {
        menagesQR.value.forEach((menage: any) => {
          if(menage.attributes.closed_at !== null) {
            const month = new Date(menage.attributes.createdAt).getMonth()
            const year = new Date(menage.attributes.createdAt).getFullYear()

            if(!sorted[year+"-"+month]){
              sorted[year+"-"+month] = {
                monthIndex: month,
                yearIndex: year,
                menages: []
              };
            }
            sorted[year+"-"+month].menages.push(menage);

          }
        })
      }
      return sorted
    })

    const eventsByMonth = computed(() => {

      let sorted: any = {}
      if(menagesQR.value) {
        menagesQR.value.forEach((menage: any) => {
          if(menage.attributes.closed_at !== null) {
            const month = new Date(menage.attributes.createdAt).getMonth()
            const year = new Date(menage.attributes.createdAt).getFullYear()

            if(!sorted[year+"-"+month]){
              sorted[year+"-"+month] = {
                monthIndex: month,
                yearIndex: year,
                menages: [],
                paiements: []
              };
            }
            sorted[year+"-"+month].menages.push(menage);

          }
        })
      }
      if(paiementsQR.value) {
        paiementsQR.value.forEach((paiement: any) => {
          const month = new Date(paiement.attributes.date).getMonth()
          const year = new Date(paiement.attributes.date).getFullYear()

          if(!sorted[year+"-"+month]){
            sorted[year+"-"+month] = {
              monthIndex: month,
              yearIndex: year,
              menages: [],
              paiements: []
            };
          }
          sorted[year+"-"+month].paiements.push(paiement);
        })
      }
      // console.log(sorted)
      return sorted
    })

    const sortedPaiements = computed(() => {

      let sorted: any = {}
      if(paiementsQR.value) {
        paiementsQR.value.forEach((paiement: any) => {
          const month = new Date(paiement.attributes.date).getMonth()
          const year = new Date(paiement.attributes.date).getFullYear()

          if(!sorted[year+"-"+month]){
            sorted[year+"-"+month] = {
              monthIndex: month,
              yearIndex: year,
              paiements: []
            };
          }
          sorted[year+"-"+month].paiements.push(paiement);
        })
      }
      return sorted
    })

    
    const addZero = (i: any) => {
      if (i < 10) {i = "0" + i}
      return i;
    }

    const displayTime = (time: any) => {
      const date = new Date(time)
      return addZero(date.getHours())+"h"+addZero(date.getMinutes())
    }

    const displayDuration = (menage: any) => {
      const duration = (nowDate.value.getTime() - new Date(menage.attributes.createdAt).getTime())
      return dateDiffToString(duration, true)
    }

    const refreshDate = () => {
      nowDate.value = new Date()
    }

    setInterval(()=>{
      refreshDate()
    }, 1000)



    return {
      menagesQR, paiementsQR, statusQR,
      openMenage,
      toggleMenage, totalMenagesTime, 
      paiementInput, addPaiement, totalPaiements, sortedPaiements, computeTotalPaiements,
      sortedMenages, computeTotalMenagesTime,
      dateobjToDisplay, dateDiffToString, displayDuration,
      eventsByMonth,
      displayTime,
      paiementShow
    }
  }
})
