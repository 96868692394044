import { createApp, h, provide } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import VueGtag from 'vue-gtag'

// Event emitter
import mitt from 'mitt'
const emitter = mitt()

// Hey hey hey it's me !
import JbLib from 'jb-lib'
import 'jb-lib/dist/jb-lib.esm.css'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUsers, faCircle, faCalendarAlt, faCamera, faSpinner, faSearch, faSignOutAlt, faDownload, faEnvelope, faVolumeUp, faVolumeMute, faArrowLeft, faArrowRight, faBars, faPhone, faFont, faPeopleArrows, faKey, faUser, faPen, faFileInvoiceDollar, faEye, faEyeSlash, faTimes, faTimesCircle, faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faPlus, faMinus, faCheckCircle, faExclamationCircle, faCog, faCogs, faBed, faHome, faDoorOpen, faHandshake, faCommentAlt, } from '@fortawesome/free-solid-svg-icons'
library.add(faUsers, faCircle, faCalendarAlt, faCamera, faSpinner, faSearch, faSignOutAlt, faDownload, faEnvelope, faVolumeUp, faVolumeMute, faArrowLeft, faArrowRight, faBars, faPhone, faFont, faPeopleArrows, faKey, faUser, faPen, faFileInvoiceDollar, faEye, faEyeSlash, faTimes, faTimesCircle, faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faPlus, faMinus, faCheckCircle, faExclamationCircle, faCog, faCogs, faBed, faHome, faDoorOpen, faHandshake, faCommentAlt)


const messages = {
  fr: {
    //title: "Ingénieur en logiciels web",
    title: "Développeur Web Polyvalent (Full-Stack)",
    small_intro_engineer: "Spécialiste en développement de solutions de gestion. Met à profit les dernières technologies du Web pour automatiser et sécuriser les tâches quotidiennes des entreprises désirant digitaliser leurs processus.",
    small_intro_fullstack: "Spécialiste de NodeJS et des frameworks Javascript. Travailleur curieux et flexible tirant parti des dernières technologies du web pour créer des applications web agiles, puissantes et sécurisées.",
    intro: `Désormais spécialiste de NodeJS, j'ai commencé par écrire du simple HTML il y a maintenant {number} ans. Au fil des années et des projets, j'ai pu voir et créer une grande partie de ce que le Web peut offrir. \n
      Intéréssé par le coté technique et structurel des projets, je me suis rapidement orienté vers la conception de logiciels plus complexes pour gérer et automatiser les tâches jugées redondantes par les entreprises (vérifier l'avancement d'un projet, créer des devis et des factures, par exemple).\n
      Pour créer des produits de qualité je me suis tourné vers les dernières technologies du Web, qui ont rapidement émergé ces dernières années grâce à leurs nombreuses qualités. En effet, elles permettent de développer des interfaces très complexes et actualisées en temps réel tout en étant incroyablement rapides et sécurisées.`,
    profile: {
      title: "Profil",
      age: "{number} ans",
      address: "Mulhouse, Grand-Est",
      driver_licence: "Permis B"
    },
    //please_visit: "Visitez mon site personnel à l'adresse {url} pour consulter mon profil complet, contenant plus d'informations sur mon parcours ainsi que des démonstrations fonctionnelles de mes anciens et actuels projets.",
    please_visit: "Visitez {url} pour consulter mon profil complet et des démonstrations fonctionnelles de mes projets.",
    career: {
      title: "Parcours professionnel",
      dut: "DUT \"Services et Réseaux de Communication (SRC)\" obtenu à l'IUT de Mulhouse.",
      licence: "Licence Professionnelle \"Web Designer\" (BAC+3) obtenue à l'IUT de Mulhouse.",
      exp_agency: "5 ans d'expérience en tant que développeur au sein d'une agence de communication",
      exp_dev: "{number} années d'expérience en développement web"
    },
    skills: {
      title: "Compétences",
      servers: "Création et gestion de serveurs",
      backend_languages: "Languages serveur",
      frameworks: "Frameworks et Gestionnaires de Contenu",
      database: "Mise en place et gestion de bases de données",
      data: "Modélisation d'architecture de données",
      web_architecture: "Architecture des pages",
      asynchronious: "Structures réactives et asynchrones",
      html_css: "HTML5 et CSS3 (bien sûr)",
      ux: "Expérience Utilisateur",
      responsive: "Design \"responsive\"",
      navigation: "Navigation et interface claires",
      utility_css: "Design par classes grâce à TailwindCSS et Bootstrap",
      writing: "Rédaction web",
      redaction: "Rédaction par mots-clés",
      seo_short: "Référencement optimisé",
      seo: "Structure de page optimisée pour le référencement",
      workflow: "Workflow",
      test_driven: "Développements Pilotés par les Tests",
      agile: "Au fait des processus Agile et DevOps",
      ci_cd: "Approche CI/CD",
      feedback_based: "Évolution par le feedback",
      autonomous: "Autonome",
      team_player: "Équipier solide",
      quality: "Recherche toujours la qualité",
      lang_en: "Anglais bilingue (niveau C2)",
      lang_de: "Bases d'Allemand (A2)",
      other: "Autres"
    },
    background: {
      title: "Mon background",
      about_me: "Historique",
      studies: "Études"
    },
    references: {
      title: "Références",
      titles: {
        date: "Date",
        tech_stack: "Infrastructure technique",
        screenshots: "Capture d'écran | Captures d'écran"
      },
      gites_spa_strasbourg: {
        description: `Site pour des maisons et appartement équipés de saunas et jacuzzis près de Strasbourg.\n
        Traduit en trois langues à la volée : l'allemand, l'anglais et le français.\n
        La sélection sur calendrier envoie une requete vers le site de gestion des réservations (Lodgify), qui répond avec un prix si les dates sont libres, ou une réponse négative le cas échéant.\n
        Le bouton de réservation redirige ensuite vers le site de gestion pour procéder au paiement de la location.` 
      },
      laurette_broll: {
        description: `Site e-commerce réalisé pour ma soeur, créatrice de céramiques à Paris.\n
        Réalisé avec Wordpress et Woocommerce, le template a été modifié en profondeur pour s'accommoder à tous types d'écrans et aux besoin de Laurette.\n
        Le site a réalisé une centaine de ventes depuis son lancement.`
      },
      new_body_concept: {
        description: `Logiciel SaaS pour un thérapeute en médecine alternative.\n
        Destiné a automatiser tous le processus de gestion de sa base de clientèle, de l'accès aux lieux de soins au paiement des prestations.\n
        Les rendez-vous, traitements, factures etc. étaient archivés et rendus accessibles aux clients pour suivre l'avancement de leur thérapie.\n 
        Le projet devait s'étendre sur une dizaine d'agences à travers l'Europe, mais s'est brusquement aretté pour une raison indépendante de ma volonté et de celle de mon client.\n 
        Il est désormais consultable en version de démonstration en cliquant sur cette case.`
      },
      naveco: {
        description: `Naveco est une entreprise de chauffeurs privés (comme Uber ou Lyft) située à Lyon.\n
        Pour se développer sur Internet, ils avaient besoin d'une application calquée sur les applis existantes, ainsi que d'un backoffice pour gérer leurs courses à distance et via une standardiste.\n
        Deux applications mobiles (une chauffeur, une client) discutaient avec le serveur en continu pour réserver, deviser et payer une course, et ensuite assigner cette course à un chauffeur aux alentours.`,
        screens: [
          "Un screenshot du tableau de bord, tôt dans le développement."
        ]
      },
    },
    contact: {
      title: "Contact",
      text: `Une question ? Un besoin ?\n
      Remplissez ce formulaire et je reviendrais vers vous au plus vite !`,
      thankyou_text: `Merci !\n
      Votre message a bien été envoyé.`,
      name: "Vos nom et prénom",
      email: "Votre adresse e-mail",
      phone: "Votre numéro de téléphone",
      message: "Écrivez votre message ici",
      send: "Envoyer",
      sent: "Message envoyé !",
    },
    words: {
      and: "et"
    },
    common: {
      showcase_website: "Site vitrine",
      ecommerce_website: "Site e-commerce",
      app_website: "Application Web"
    }
  },
  en: {
    //title: "Web Software Engineer",
    title: "Full-Stack developer",
    small_intro_engineer: "Specialised in developing management solutions, I take advantage of the latest Web technologies to automate and secure the day-to-day tasks of any company wishing to digitalize their processes.",
    small_intro_fullstack: "NodeJS and Javascript frameworks specialist. A  curious and passionate worker who takes advantage of the latest Web technologies to create fast, powerful and secure web apps.",
    intro: `Now a NodeJS specialist, I started by writing simple HTML {number} years ago now. Along the years and the projects I have seen and created many parts of what the Web can offer.\n
      Interested in the technical and structural side of projects, I quickly leaned toward engineering more complex applications to manage and automate tasks that are deemed redundant by companies (eg. checking a project's progress, create quotes and invoices, ...).\n
      To create quality products, I switched to the latest web technologies, that quickly emerged thanks to their numerous advantages. They enable us to develop complex and real-time interfaces while being incredibly fast and secure. `,
      profile: {
      title: "Profile",
      age: '{number} years old',
      address: "Mulhouse, France",
      driver_licence: "Driving licence"
    },
    //please_visit: "Please visit my website at {url} to discover my complete profile, which contains more informations about my career and has working demonstrations of current and past projects.",
    please_visit: "Please visit {url} to discover my complete profile and working demonstrations of my projects.",
    career: {
      title: "Career",
      dut: "DUT (Advanced Federal Diploma of Higher Education) \"Communication Services and Networks\" at IUT de Mulhouse.",
      licence: "Professional licence (Bachelor) \"Web Designer\" obtained at IUT de Mulhouse.",
      exp_agency: "5 years as a web developer in a communication agency",
      exp_dev: "{number} years of experience as a developer"
    },
    skills: {
      title: "Skills & stacks",
      servers: "Server setup and management",
      backend_languages: "Backend languages",
      frameworks: "Frameworks and Content Managers",
      database: "Database setup and management",
      data: "Data architecture",
      web_architecture: "Website architecture",
      asynchronious: "Asynchronious and reactive structures",
      html_css: "HTML5 and CSS3 (of course)",
      ux: "User Experience",
      responsive: "Responsive design",
      navigation: "Clear navigation and interfaces",
      utility_css: "Utility based design with TailwindCSS and Bootstrap",
      writing: "Content writing",
      redaction: "Keywords based redaction",
      seo_short: "Search Engine Optimized",
      seo: "Search engine optimized (SEO) page structure",
      workflow: "Workflow",
      test_driven: "Test driven development",

      other: "Other",
      agile: "Agile and DevOps concious",
      ci_cd: "CI/CD approach",
      feedback_based: "Feedback based growth",
      autonomous: "Autonomous",
      team_player: "Team player",
      quality: "Always strives for quality",
      bilingual: "Speaks French and English",
      lang_fr: "Native French speaker",
      lang_en: "English spoken (C2)",
      lang_de: "Basics in German (A2)",
    },
    background: {
      title: "My background",
      about_me: "About me",
      studies: "Studies"
    },
    langages: {
      title: "Langages / Frameworks"
    },
    references: {
      title: "Work examples",
      titles: {
        date: "Date",
        tech_stack: "Technology stack",
        screenshots: "Screenshot | Screenshots"
      },
      gites_spa_strasbourg: {
        description: `Website for spa and sauna equipped houses and apartements located near Strasbourg.\n
        Translated in three languages on the fly : german, english and french.\n
        Selecting a date on the calendar sends a request to the website that handles reservations (Lodgify), who responds with a price if the dates are available, or a negative response otherwise.\n
        The reservation button then redirects to Lodgify to proceed to the booking checkout.` 
      },
      laurette_broll: {
        description: `E-commerce website created for my sister, she is a ceramics artist based in Paris.\n
        Made with Wordpress and Woocommerce, the template has been deeply modified to accommodate to every type of screen and to Laurette's needs.\n
        The website helped her make more than a hundred sales since it's launch.`
      },
      new_body_concept: {
        description: `SaaS website for an alternative medicine therapist.\n
        Intended to automate all the management process of its customer base, from access to healthcare facilities to payment of services.\n
        Appointments, treatments, invoices etc. were archived and made accessible online to clients to monitor the progress of their therapy.\n
        The project was to expand into several agencies across Europe, but abruptly came to a stop for a reason beyond my control and that of my client.\n 
        It is now available as a demo product, and you can access it by clicking this panel.`
      },
      naveco: {
        description: `Naveco is a private driver company (like Uber or Lyft) located in Lyon.\n
         To estasblish themselves on the Internet, they needed an application modeled on existing apps, as well as a back office to manage their rides remotely take bookings via a phone operator.\n
         Two mobile applications (a driver, a customer) communicated with the server continuously to book, quote and pay for a ride, and then assign that ride to a nearby driver.`,
        screens: [
          "An early screenshot of the application's dashboard."
        ]
      }
    },
    contact: {
      title: "Contact",
      text: `Questions? Requests? \n
        Fill this form and I'll get back to you as soon as possible!`,
      thankyou_text: `Thank you !\n
        Your message has been sent successfully.`,
      name: "Your full name",
      email: "Your e-mail address",
      phone: "Your phone number",
      message: "Type your message here",
      send: "Send",
      sent: "Message sent!"
    },
    words: {
      and: "and"
    },
    common: {
      showcase_website: "Showcase website",
      ecommerce_website: "E-shop website",
      app_website: "Web-App"
    }
  }
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: ['fr'],
  messages
})

// Set environment dependent variables
const EnvironmentVariables = {
  ContentManagerUrl: 'http://192.168.1.11:5079', // local dev
}

if (process.env.VUE_APP_MODE == "live")
  EnvironmentVariables['ContentManagerUrl'] = "https://joris-broll.com/api"


import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { setContext } from '@apollo/client/link/context'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: EnvironmentVariables['ContentManagerUrl'] + '/graphql',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const defaultClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

const app = createApp({
  data() {
    return {
      sharedState: store.state
    }
  },
  setup() {
    provide(DefaultApolloClient, defaultClient)
  },
  render() {
    return h(App)
  }
})

// Manual import (specified in documentation)
import 'v-calendar/dist/style.css';

app.config.globalProperties.$ContentManagerUrl = EnvironmentVariables['ContentManagerUrl']
if (process.env.NODE_ENV == "development" || process.env.VUE_APP_MODE == "test")
  app.config.globalProperties.$AppTesting = true
else
  app.config.globalProperties.$AppTesting = false
app.provide('$ContentManagerUrl', EnvironmentVariables['ContentManagerUrl'])
app.provide('$emitter', emitter)
app.use(i18n).use(router).use(store).use(JbLib).use(VueGtag, { config: { id: "G-41KEESGP9T" } }, router)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')
