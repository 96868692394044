
import { defineComponent, computed, reactive, ref } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { searchHelper, toSearchList } from '@/modules/search'
import { DatePicker } from 'v-calendar'
import _ from 'lodash'

import searchReservationsQuery from '@/graphql/reservation/search.gql'

export default defineComponent({
  name: 'UserForm',
  props: ['element', 'display', 'type'],
  components: {
    DatePicker
  },
  setup() {
    const preselectedReservation_initial = { id:0, name:'' }
    let preselectedReservation = ref(_.cloneDeep(preselectedReservation_initial))

    const form_initial = {
      //name: '',
      date: new Date(),
      custom_products: [] as any[],
      client_name: '',
      client_email: '',
      client_phone: '',
      client_arrival: new Date(),
      client_departure: new Date(),
      paid: 0,
      due: 0,
      reservationId: "0",
    }
    let form = ref(_.cloneDeep(form_initial))

    ////////// Field : Reservation - Search
    const { searchFunction: searchReservations, ElementsList: searchedReservations } = searchHelper('reservations', searchReservationsQuery)

    const searchedReservationsList = computed(() => {
      return toSearchList(searchedReservations, 'reservation')
    })    

    const addCustomProductToFormList = () => {
      form.value.custom_products.push({name: "", custom_text: "", price: 0, quantity: 1, type: "custom"})
      console.log(form.value.custom_products)
    }
    const removeCustomProductFromFormList = (index: number) => {
      form.value.custom_products.splice(index, 1)
    }

    const reset = () => {
      form.value = _.cloneDeep(form_initial)
      preselectedReservation.value = _.cloneDeep(preselectedReservation_initial)
    }


    // Testing purposes
    const fillForm = () => {
      //form.name = 'test_name'
      form.value.client_name = 'test_client_name'
      form.value.client_email = 'test_client_email'
      form.value.client_phone = 'test_client_phone'
      form.value.paid = 1000
      form.value.due = 2000
    }

    return  {
      form,

      searchReservations,
      searchedReservationsList,
      preselectedReservation,

      addCustomProductToFormList,
      removeCustomProductFromFormList,

      reset,
      fillForm
    }
  }
})
