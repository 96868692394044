
import { defineComponent } from 'vue';
//import Multiselect from '@vueform/multiselect'
export default defineComponent({
  name: 'PublicView',
  data() {
    return {
      scrollClass: "scrolled-top"
    }
  },
  methods: {
    handleScroll() {
      const mainContainerElement: HTMLCollection | null = document.getElementsByClassName("jb-main-container")
      if (mainContainerElement[0].scrollTop > 50 ) {
        this.scrollClass = "scrolling"
      } else {
        this.scrollClass = "scrolled-top"
      }
    }
  },
});
