
import { defineComponent, ref, reactive, inject } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { searchValueNulllIfZero } from '@/modules/search'
import createInvoiceMutation from '@/graphql/invoice/create.gql'

import InvoiceForm from '@/components/forms/Invoice.form.vue'


export default defineComponent({
  name: 'InvoiceCreate',
  components: {
    InvoiceForm
  },
  setup() {
    const emitter: any =  inject('$emitter')

    const invoiceFormRef = ref<null | any>(null)

    // Apollo Create Query 
    const { mutate: createInvoice, onDone: createInvoiceDone, onError: createInvoiceError } = useMutation(createInvoiceMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })
    createInvoiceDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createInvoiceError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    createInvoiceDone((QR) => {
      emitter.emit("create-toast", {
        props: {
          title: "Facture créée",
          //text: QR.data.createInvoice.invoice.name,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-create")
      emitter.emit("invoices-refresh")
    })
    createInvoiceError((error) => {
      console.log('Create invoice Error')
      console.log(error)
    })


    ////////// SUBMIT Modal
    const submit_modal = () => {
      const form = invoiceFormRef.value.form
      
      const custom_products: any[] = invoiceHelpers.customProductsToStrapi(form.custom_products)

      const reservationId = searchValueNulllIfZero(form.reservationId)

      console.log(reservationId)
      
      
      const queryInput = { 
        data: {
          date: helpers.dateobjToISO(form.date),
          custom_products: JSON.parse(JSON.stringify(custom_products)),
          client_name: form.client_name,
          client_email: form.client_email,
          client_phone: form.client_phone,
          client_arrival: helpers.dateobjToISO(form.client_arrival),
          client_departure: helpers.dateobjToISO(form.client_departure),
          paid: form.paid,
          due: form.due,
          reservation: reservationId,
        }
      }

      createInvoice(queryInput)
    }

    // Testing purposes
    const fillForm = () => {
      invoiceFormRef.value.fillForm()
    }

    return {
      invoiceFormRef,
      createInvoice,
      createStatus,

      submit_modal,
      fillForm
    }
  }
});
