
import { defineComponent } from "vue"

export default defineComponent({
  name: 'ContactForm',
  data () {
    return {
      formSent:false,
      formInputs: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      validationStatus: {
        name: 'initial',
        email: 'initial',
        phone: 'initial',
        message: 'initial'
      }
    }
  },
  methods: {
    validateAndSendForm: async function () {
      var formData = new FormData(document.getElementById('contact-form') as HTMLFormElement)

      let formIsValid = true

      for (const [key, value] of Object.entries(this.formInputs)) {
        if(value === "") {
          (this.validationStatus as { [key: string]: string })[key] = "invalid"
          formIsValid = false
        } else {
          (this.validationStatus as { [key: string]: string })[key] = "valid"
        }
      }

      if(formIsValid){
        const response = await fetch("/sendEmail", {
          method: "POST",
          body: formData
        })

        if (response.ok) {
          this.formSent = true
          const data = await response.json()
          return data
        }
      }
    }
  }
})
