
import { defineComponent, inject } from 'vue'
import { Emitter } from 'mitt'

import InvoiceCreateModal from '@/components/modals/InvoiceCreate.vue'

export default defineComponent({
  name: 'AdminView',
  components: {
    InvoiceCreateModal,
  },
  setup(){
    const emitter: any = inject('$emitter')

    return {
      emitter
    }
  },
  data() {
    return {
      username: sessionStorage.getItem('username'),
      role: sessionStorage.getItem('role'),
    }
  },
  methods: {
  }
});
