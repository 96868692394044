import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"400px","margin":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JbLoginForm = _resolveComponent("JbLoginForm")!
  const _component_JbPanel = _resolveComponent("JbPanel")!
  const _component_JbMainContainer = _resolveComponent("JbMainContainer")!
  const _component_JbWebpage = _resolveComponent("JbWebpage")!

  return (_openBlock(), _createBlock(_component_JbWebpage, null, {
    default: _withCtx(() => [
      _createVNode(_component_JbMainContainer, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_JbPanel, {
              title: "Login",
              icon: "key",
              id: "login"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_JbLoginForm, {
                  onSubmit: _ctx.authenticate,
                  status: _ctx.status
                }, null, 8, ["onSubmit", "status"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}