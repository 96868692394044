import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex gap-6 text-3xl text-neutral-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      href: "#",
      class: _normalizeClass([_ctx.$store.getters.currentLocale === 'fr' ? 'text-neutral-200' : '', "cursor-pointer"]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLocale('fr')))
    }, "Fr", 2),
    _createElementVNode("p", {
      href: "#",
      class: _normalizeClass([_ctx.$store.getters.currentLocale === 'en' ? 'text-neutral-200' : '', "cursor-pointer"]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLocale('en')))
    }, "En", 2)
  ]))
}