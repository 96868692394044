
import { defineComponent } from "vue"
import LocaleSwitch from '@/components/LocaleSwitch.vue'
import ContactForm from '@/components/ContactForm.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    LocaleSwitch,
    ContactForm
  },
  data () {
    return {
      locale: 'fr',
      developerYears: 0,
      reference: '',
      myAge: 0,
    }
  },
  methods: {
    validateAndSendForm: async function () {
      var formData = new FormData(document.getElementById('contact-form') as HTMLFormElement)

      const response = await fetch("/sendEmail", {
        method: "POST",
        body: formData
      })

      if (response.ok) {
        const data = await response.json()
        return data
      }
    }
  },
  created: function () {
    this.$i18n.locale = 'fr'
    const today = new Date()
    const birthday = new Date('1991/07/23')
    const developerStartDate = new Date('2010/01/01')
    const age = Math.floor(Math.abs(today.getTime() - birthday.getTime()) / 31536000000)
    const developerYears = today.getFullYear() - developerStartDate.getFullYear()

    this.myAge = age
    this.developerYears = developerYears

    // Meta description
    // document.querySelector('meta[name="description"]').setAttribute('content', 'Blabla')
  }
})
