export { default as invoiceHelpers } from './helpers/invoiceHelpers'

export const helpers = {
  dateobjToISO(date: Date): string {
    return date.toISOString().substr(0, 4)+'-'+date.toISOString().substr(5, 2)+'-'+date.toISOString().substr(8, 2)
  },

  getByIdInArray(id: number, array: any[]): any {
    for (const item of array) {
      if (item.id === id)
        return item
    }
    return 0
  },

  unfreezeProducts(products: any[]): any[] {
    const rData: any[] = []
    for (const product of products) {
      const b: { [key: string]: any } = {}
      for (const property in product) {
        if (typeof product[property] === 'object') {
          const c: { [key: string]: any } = {}
          for (const subProperty in product[property]) {
            c[subProperty] = product[property][subProperty]

            b[property] = c
          }
        } else {
          b[property] = product[property]
        }
      }
      rData.push(b)
    }
    return rData
  }
}