<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" appear>
      <component :is="Component" />
    </transition>
  </router-view>
  <!--<CookiesNotice/>-->
</template>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Consolas;
  src: url(assets/fonts/Consola.ttf);
  font-weight: normal;
}
@font-face {
    font-family: 'gothambook';
    src: url('assets/fonts/gothambook-webfont.woff2') format('woff2'),
         url('assets/fonts/gothambook-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
  font-family: gothambook;
}
</style>
