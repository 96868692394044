
import { defineComponent } from "vue"
import LocaleSwitch from '@/components/LocaleSwitch.vue'

export default defineComponent({
  name: 'ReferencesView',
  components: {
    LocaleSwitch
  },
  data () {
    return {
      locale: 'fr',
      references: {
        gites_spa_strasbourg: {
          title: 'Gites & Spa Strasbourg',
          link: "https://www.gites-spa-strasbourg.fr/",
          type: "showcase_website",
          cover_img: require('@/assets/references/gss/gites_spa_strasbourg.png'),
          tech_stack: "NodeJS, Vue, Strapi, PostgreSQL",
          date: "2021"
        },
        laurette_broll: {
          title: 'laurette broll ceramic',
          link: "https://laurettebroll.com/",
          type: "ecommerce_website",
          cover_img: require('@/assets/references/lb/laurette_broll.png'),
          tech_stack: "PHP, Wordpress",
          date: "2020"
        },
        new_body_concept: {
          title: 'new body concept',
          link: "https://nbc.joris-broll.com/",
          type: "app_website",
          cover_img: require('@/assets/references/nbc/new_body_concept.png'),
          tech_stack: "NodeJS, Vue, Strapi, PostgreSQL, SumUp",
          date: "2021"
        },
        naveco: {
          title: 'Naveco',
          type: "app_website",
          cover_img: require('@/assets/references/naveco/naveco.png'),
          tech_stack: "Ruby, Ruby on Rails, PostgreSQL, Ajax, Cordova, Google Maps API, Heroku, AWS",
          date: "2015",
          screens: [
            require('@/assets/references/naveco/screenshot_admin_interface.png')
          ]
        }
      }
    }
  },
  methods: {
  }  
})
