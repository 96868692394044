
import { defineComponent, inject, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import loginMutation from '@/graphql/user/login.gql'
import meQuery from '@/graphql/user/me.gql'

export default defineComponent({
  name: 'LoginView',
  setup() {
    const router = useRouter()
    let status = 'ok'

    const getMeEnabled = ref(false)
    const { mutate: _login, onDone: loginDone } = useMutation(loginMutation)
    const { result: getMeResult } = useQuery(meQuery, null, () => ({ enabled: getMeEnabled.value }))


    // On button click we send the auth request
    const authenticate = async (formValues: {login: string; password: string}) => {
      sessionStorage.removeItem('jwt')

      if (formValues.login && formValues.password) {
        try {
          const fields = {input: { identifier: formValues.login, password: formValues.password }}
          _login(fields)

        } catch(err) {
          console.log("Error with authenticating request.")
          console.log(err)
          status = 'error'
        }

      } else {
        console.log("Invalid login submitted")
      }
    }
    
    // When the request is done we don't yet receive the user information, we fetch them using the jwt we just received
    loginDone(result => {
      console.log(result.data)
      
      if(result.data.login.jwt) {
        sessionStorage.setItem('jwt', result.data.login.jwt)
        getMeEnabled.value = true
      } else {
        console.log('Logins are wrong')
        status = 'error'
      }
    })

    // This is triggered when we receive the user informations
    watch(getMeResult, (newValue) => {
      sessionStorage.setItem('id', newValue.me.id)
      sessionStorage.setItem('username', newValue.me.username)
      sessionStorage.setItem('role', newValue.me.role.name)
      //
      
      if(router.currentRoute.value.redirectedFrom) {
        router.push(router.currentRoute.value.redirectedFrom.fullPath)
      } else {
        router.push(newValue.me.role.name.toLowerCase())
      }
        //if (router.redirectedFrom) { console.log(router.redirectedFrom)}
      console.log('Login successful : '+newValue.me.username)
    })

    return {
      authenticate,
      status
    }
  }
});
