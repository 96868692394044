
import { defineComponent, inject, computed, reactive, ref } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { Emitter } from 'mitt'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { DatePicker } from 'v-calendar'
import getInvoicesQuery from '@/graphql/invoice/gets.gql'
import getInvoiceBatchQuery from '@/graphql/invoice/gets_batch.gql'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import sendByEmailInvoiceMutation from '@/graphql/invoice/sendByEmail.gql'

import InvoiceUpdateModal from '@/components/modals/InvoiceUpdate.vue'

export default defineComponent({
  name: 'InvoicesView',
  components: {
    InvoiceUpdateModal,
    DatePicker
  },
  setup() {
    // get Global properties
    const emitter: any = inject('$emitter')

    // generate Invoice
    const { loading: generateLoading, mutate: _generateInvoice, onDone: generateDone } = useMutation(generateInvoiceMutation)

    const generateInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _generateInvoice(queryInput)
    }
    generateDone(() => {
      emitter.emit("create-toast", {
        props: {
          title: 'PDF Généré',
          text: "Le PDF a été généré avec succès.",
          icon: "check-circle",
        }
      })
    })

    // sendByEmail Invoice
    const { mutate: _sendByEmailInvoice } = useMutation(sendByEmailInvoiceMutation)

    const sendByEmailInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _sendByEmailInvoice(queryInput)
    }

    // get Invoices list
    const invoiceListPaging = reactive({
      start: 0,
      limit: 100,
      max: 0
    })
    const { result: getInvoicesResult, loading: getInvoicesLoading, error: getInvoicesError, refetch: refetchInvoices } = useQuery(getInvoicesQuery, () => ({ start: invoiceListPaging.start, limit: invoiceListPaging.limit }))
    const invoices = useResult(getInvoicesResult, null, data => data.invoices.data)
    invoiceListPaging.max = useResult(getInvoicesResult, null, data => data.invoices.meta.pagination.total) as any

    emitter.on('invoices-refresh', () => {
      refetchInvoices()
    })

    // get Invoices list
    const invoiceBatchListPaging = reactive({
      start: new Date(),
      limit: new Date(),
      count: 0
    })


    const { result: getInvoiceBatchResult, loading: getInvoiceBatchLoading, onResult: getInvoiceBatchOnResult } = useQuery(getInvoiceBatchQuery, () => ( {start: helpers.dateobjToISO(invoiceBatchListPaging.start), limit: helpers.dateobjToISO(invoiceBatchListPaging.limit)} ))
    const invoiceBatch = useResult(getInvoiceBatchResult, null, data => data.invoices.data)
    invoiceBatchListPaging.count = useResult(getInvoiceBatchResult, null, data => data.invoices.meta.pagination.total) as any

    
    const invoiceBatchWithFileCount = ref(0)
    getInvoiceBatchOnResult (QR => {
      invoiceBatchWithFileCount.value = 0
      for(const invoice in invoiceBatch.value) {
        if(invoiceBatch.value[invoice].file && invoiceBatch.value[invoice].file.length > 0) {
          invoiceBatchWithFileCount.value++
        }
      }
    })

    // Invoice ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id 
      emitter.emit("open-modal-invoice-update")
    }

    const buttonSentence = computed(() => {
      if(getInvoiceBatchLoading.value) {
        return 'Chargement...'
      } else {
        if(invoiceBatchListPaging.count === 0) {
          return 'Aucune facture trouvée'
        } else {
          return invoiceBatchListPaging.count+' facture(s), dont '+invoiceBatchWithFileCount.value+' avec PDF.'
        }
      }
    })

    return {
      generateInvoice,
      sendByEmailInvoice,

      invoices,
      invoiceListPaging,
      getInvoicesLoading,
      getInvoicesError,

      invoiceBatchListPaging,
      invoiceBatch,
      invoiceBatchWithFileCount,

      setupEditModal,
      updateId,
      emitter,

      buttonSentence,

      invoiceHelpers
    }
    
  }
})
