import { createStore } from 'vuex'

// /!\ Local Storage save everything as string

export default createStore({
  state: {
    locale: '',
  },
  getters: {
    currentLocale(state) {
      return state.locale
    }
  },
  mutations: {
    changeLocale(state, newLocale) {
      state.locale = newLocale
      //console.log(state.locale)
    }
  },
  actions: {
  },
  modules: {
  }
})