import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Public from './views/Public.vue'
import Home from './views/public/Home.vue'
import HomeA4 from './views/public/HomeA4.vue'
import References from './views/public/References.vue'
import Menage from './views/public/MenageView.vue'
import MenageHass from './views/public/MenageHassView.vue'

import ALoginView from './views/LoginView.vue'

// Iori space
import IoriView from './views/IoriView.vue'
  import IoriHomeView from './views/iori/HomeView.vue'
  import IoriInvoicesView from './views/iori/InvoicesView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    component: Public,
    children: [
      {
        path: '',
        component: Home,
      },
      {
        path: '/references/:name',
        component: References
      },
      {
        path: 'a4',
        component: HomeA4
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: ALoginView,
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: '/login'
  },
  {
    path: '/menage',
    component: Menage,
    //meta: { authorize: ["Iori", "Iori-FemmeMenage"] },
  },
  {
    path: '/hass68',
    component: MenageHass,
    //meta: { authorize: ["Iori", "Iori-FemmeMenage"] },
  },
  {
    path: '/iori',
    component: IoriView,
    meta: { authorize: ["Iori"] },
    children: [
      {
        path: '',
        name: 'AdminHome',
        component: IoriHomeView,
      },
      {
        path: 'factures',
        component: IoriInvoicesView
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  // Is the route protected
  if (to.matched.some(record => record.meta.authorize)) {

    // And is the user connected ?
    const role = sessionStorage.getItem("role")

    if (role) {
      // And is the user authorized to be there ?
      if ((to.meta.authorize as Array<string>).includes(role)) {
        // Alright you may enter
        next()
      } else {
        // Redirect this impostor !
        next('/login')
      }
    } else {
      // Redirect this person
      next('/login')
    }
  } else {
    // If user wants to log out
    if (to.redirectedFrom) {
      if (to.redirectedFrom.name === "Logout") { sessionStorage.clear() }
    }
    next()
  }
})

export default router
