import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "whitespace-pre-line mb-8" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = ["value"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "whitespace-pre-line my-52 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.formSent === false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('contact.text')), 1),
            _createElementVNode("form", {
              name: "contact-form",
              id: "contact-form",
              onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.validateAndSendForm && _ctx.validateAndSendForm(...args)), ["prevent"]))
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInputs.name) = $event)),
                name: "name",
                placeholder: _ctx.$t('contact.name'),
                class: _normalizeClass(["bg-neutral-800", _ctx.validationStatus.name === 'invalid' ? 'border border-red-900': ''])
              }, null, 10, _hoisted_3), [
                [_vModelText, _ctx.formInputs.name]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInputs.email) = $event)),
                name: "email",
                placeholder: _ctx.$t('contact.email'),
                class: _normalizeClass(["bg-neutral-800", _ctx.validationStatus.email === 'invalid' ? 'border border-red-900': ''])
              }, null, 10, _hoisted_4), [
                [_vModelText, _ctx.formInputs.email]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "tel",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formInputs.phone) = $event)),
                name: "phone",
                placeholder: _ctx.$t('contact.phone'),
                class: _normalizeClass(["bg-neutral-800", _ctx.validationStatus.phone === 'invalid' ? 'border border-red-900': ''])
              }, null, 10, _hoisted_5), [
                [_vModelText, _ctx.formInputs.phone]
              ]),
              _withDirectives(_createElementVNode("textarea", {
                name: "message",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formInputs.message) = $event)),
                placeholder: _ctx.$t('contact.message'),
                class: _normalizeClass(["bg-neutral-800", _ctx.validationStatus.message === 'invalid' ? 'border border-red-900': ''])
              }, null, 10, _hoisted_6), [
                [_vModelText, _ctx.formInputs.message]
              ]),
              _createElementVNode("input", {
                type: "submit",
                value: _ctx.$t('contact.send')
              }, null, 8, _hoisted_7)
            ], 32)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('contact.thankyou_text')), 1)
          ]))
    ]),
    _: 1
  }))
}